<div property="schema:text" class="aboutUs-container">
  <div class="abUs-banner">
    <img src="../../../../assets/images/abUs-banner.webp" />
  </div>
  <div class="row p-text">
    <div class="col-lg-6">
      <img src="../../../../assets/images/holding-hands.webp" />
    </div>
    <div class="col-lg-6">
      <p>Every morning, millions of Nigerians wake up to face a brutal reality: unemployment. It is not just a
        statistic but a fight defining people, families, and communities. Despite having the desire, talent, and
        ambition, countless Nigerians remain without meaningful means of livelihood. At Worknigeria, we refuse to
        accept this as the status quo. Our vision is simple yet powerful: to put Nigerians back to work.</p>
      <p class="m-0">As a subsidiary of The Innovate Africa Foundation, Worknigeria was founded in 2021 with the
        mission to help the huge Nigerian workforce find not only employment but also opportunities to develop their
        skills in their chosen careers. With advanced technology and strategic partnerships, we are establishing a
        platform where careers thrive and businesses find the talent they need.</p>
    </div>
  </div>
  <!-- Vision and Mission Section -->
  <div class="row vision-mission">
    <div class="col-lg-6 vision-card">
      <div class="vision-icon">
        <img src="../../../../assets/images/vision-icon.webp" />
      </div>
      <h3>Our Vision</h3>
      <p><b>To put one million Nigerians to work every year.</b><br>
        In a society where unemployment looms as a serious concern, this vision offers more than simply a
        goal—it is a promise. A promise to improve lives, spark ambitions, and rebuild the Nigerian workforce,
        one job at a time. <br>
        At Worknigeria, we believe that meaningful employment is the cornerstone of a
        functioning society, and we are on a relentless path to making this a reality for millions of Nigerians.
        Management software provides full automation of membership renewals and payments</p>
    </div>
    <div class="col-lg-6 mission-card">
      <div class="mission-icon">
        <img src="../../../../assets/images/mission-icon.webp" />
      </div>
      <h3>Our Mission</h3>
      <p>To serve both our corporate clients and job seekers with passion and devotion by offering them a range of
        specialized services that ultimately help us put one million Nigerians to work each year.<br>
        Our mission is anchored in the understanding that true change happens when everyone wins. For job seekers,
        this means creating access to opportunities that match their talents and aspirations. For businesses, it is
        about delivering excellent people and bespoke recruitment solutions. Together, we are bridging the gap
        between potential and opportunity, paving the road for a stronger, more prosperous Nigeria. 
      </p>
    </div>
  </div>
  <!-- What we do Section -->
  <div class="what-we-do">
    <h1>What we do</h1>
    <p class="what-we-do-desc">We offer experience, creativity, and a human touch to all facets of your workforce management, from hiring
      to employee engagement, payroll administration, and policy development. Together, we can turn obstacles into
      opportunities and create a vibrant, creative company via:
    </p>
    <div class="row">
      <div class="col-lg-6 what-we-do-img">
        <img src="../../../../assets/images/looking-for.webp" />
      </div>
      <div class="col-lg-6">
        <!-- One -->
        <div class="what-we-do-text">
          <img src="../../../../assets/images/one-icon.webp" />
          <div>
            <h3>Recruitment Services:</h3>
            <p>We connect businesses with the right talent by matching skills, experience, and values to organizational
              needs.</p>
          </div>
        </div>
        <!-- Two -->
        <div class="what-we-do-text">
          <img src="../../../../assets/images/two-icon.webp" />
          <div>
            <h3>Background Check Services:</h3>
            <p>We carry out a thorough verification of employee certifications, training, educational claims, and more…
            </p>
          </div>
        </div>
        <!-- Three -->
        <div class="what-we-do-text">
          <img src="../../../../assets/images/three-icon.webp" />
          <div>
            <h3>Manpower Outsourcing:</h3>
            <p>We handle the complexities of staffing while you focus on your core business operations.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <!-- Four -->
        <div class="what-we-do-text">
          <img src="../../../../assets/images/four-icon.webp" />
          <div>
            <h3>Job Board:</h3>
            <p>From immigration compliance to onboarding, we provide comprehensive solutions for managing expatriate
              employees.</p>
          </div>
        </div>
        <!-- Five -->
        <div class="what-we-do-text">
          <img src="../../../../assets/images/five-icon.webp" />
          <div>
            <h3>Training and Development:</h3>
            <p>Our platform offers job seekers and employers a space to connect seamlessly, creating opportunities for
              career and organizational growth.</p>
          </div>
        </div>
        <!-- Six -->
        <div class="what-we-do-text">
          <img src="../../../../assets/images/six-icon.webp" />
          <div>
            <h3>Expatriate Management:</h3>
            <p>From immigration compliance to onboarding, we provide comprehensive solutions for managing expatriate
              employees.</p>
          </div>
        </div>
        <!-- Seven -->
        <div class="what-we-do-text">
          <img src="../../../../assets/images/seven-icon.webp" />
          <div>
            <h3>HR Consulting:</h3>
            <p>We partner with organizations to design and implement strategies that drive workforce excellence.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="email-form">
          <p>Want to Learn more about any of our services?</p>
          <form [formGroup]="emailForm">
            <div class="custom-dropdown">
              <select class="form-control" formControlName="service">
                <option value="" disabled selected hidden>Select Service</option>
                <option value="Recruiment Services">Recruitment Services</option>
                <option value="Background Check Services">Background Check Services</option>
                <option value="Manpower Outsourcing">Manpower Outsourcing</option>
                <option value="Job Board">Job Board</option>
                <option value="Training and Development">Training and Development</option>
                <option value="Expatriate Management">Expatriate Management</option>
                <option value="HR Consulting">HR Consulting</option>
              </select>
              <img src="../../../../assets/images/arrow-down.webp" alt="dropdown icon" class="dropdown-icon" />
            </div>
            <input placeholder="Enter your email" type="email" class="custom-input form-control"
              formControlName="email" />
            <div *ngIf="f.email.touched && f.email.invalid">
              <span class="text-left text-danger pb-1" *ngIf="f.email.errors.required">Email is required
              </span>
              <span class="text-left text-danger pb-1" *ngIf="f.email.errors?.pattern">
                Email is not valid.
              </span>
            </div>
            <textarea class="custom-textarea form-contol" rows="3" maxlength="300" placeholder="Send us a message"
              formControlName="message"></textarea>
            <div class="submit-div">
              <p>Or call us on <a href="tel:09137000206"><b><u>09137000206</u></b></a></p>
              <button (click)="sendEmail()">Send</button>
            </div>
          </form>
          <hr class="line-hr" />
          <p class="or-text">or</p>
          <div class="text-center">
            <button class="btn-download" (click)="downloadBrochure()">Download our brochure here</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Why choose worknigeria Section -->
  <div class="why-choose">
    <div class="row">
      <div class="col-lg-6 why-choose-text">
        <h2>Why choose Worknigeria?</h2>
        <p>We are not just another recruitment platform. We are a community of individuals and businesses working
          together for a better future.</p>
        <ul class="custom-list">
          <li *ngFor="let item of items">
            <h4>{{ item.heading }}</h4>
            <p>{{ item.para }}</p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 why-choose-img">
        <img src="../../../../assets/images/why-choose-us.webp" />
      </div>
    </div>
  </div>
  <!-- Awards and Certifications Section -->
  <div class="awards">
    <h2>Award and Certifications</h2>
    <div class="row d-flex align-items-center">
      <div class="col-lg-7 awards-text">
        <p>Our drive to excellence has garnered us various medals and certifications, reflecting our commitment to
          quality and innovation. From industry honors to important certifications like ISO, these achievements
          underline our unwavering quest of delivering great solutions and upholding the highest standards in
          everything we do.</p>
      </div>
      <div class="col-lg-5">
        <img src="../../../../assets/images/iso-certification.webp" />
      </div>
    </div>
  </div>
  <!-- Trusted by Section -->
  <div class="trusted-by-section">
    <h2>Trusted by leading Employers in Nigeria:</h2>
    <p>We are proud to partner with various clients across industries who trust us to deliver great solutions
      tailored to their particular needs. Our client base shows our commitment to quality, innovation, and
      excellence.</p>
    <div class="ticker-outer-container">
      <div class="ticker-inner-container">
        <div class="ticker-container">
          <div *ngFor="let logo of logos" class="ticker-item">
            <img [src]="logo" class="ticker-img" alt="Logo" />
          </div>
          <div *ngFor="let logo of logos" class="ticker-item">
            <img [src]="logo" class="ticker-img" alt="Logo" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Testimonials Section -->
  <div class="testimonials-section">
    <h2>Testimonials</h2>
    <p>These are just few testimonials from what our users have said about us</p>
    <!-- Testimonials Cards -->
    <div class="cards-container">
      <div class="card testimonials-card" *ngFor="let card of testimonials let i = index">
        <img [src]="card.logo" alt="{{ card.heading }}" />
        <h4> {{ card.heading }} </h4>
        <p class="m-0"> {{ card.para }} </p>
        <p> {{ card.saidBy }} </p>
        <p class="learn-more" (click)="goToSignUp()">Learn More —></p>
      </div>
    </div>
  </div>
  <!-- Our values Section -->
  <div class="our-values">
    <h2>Our Values</h2>
    <div class="row">
      <div class="col-lg-6 our-values-img">
        <img src="../../../../assets/images/values-icon.webp" />
      </div>
      <div class="col-lg-6 our-values-text">
        <p>We recognize that our success as a Human Resource Consulting firm in Nigeria is intricately linked to the
          well-being, development, and fulfillment of each team member as well as to delivering excellent customer
          satisfaction.</p>
        <p>Thus, our company culture is generally founded on the principles of collaboration, innovation, leadership,
          excellence, and integrity. These values are not just words on paper; they are also the guiding light behind
          everything we do, shaping our decisions, actions, and relationships.</p>
        <ul>Itemized below are the key tenets of our culture…
          <li>Our People First</li>
          <li>Customer Satisfaction</li>
          <li>Continuous Learning and Improvement</li>
          <li>Empowerment and Ownership</li>
          <li>Team Collaboration and Support</li>
          <li>Corporate Social Responsibility</li>
          <li>Resilience</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Join Us Section -->
  <div class="join-us">
    <div class="row">
      <div class="col-lg-6 join-us-text">
        <h2>Join Us</h2>
        <p>Whether you’re an employer seeking the perfect talent or a job seeker ready to take the next step in your 
          career, Worknigeria is here for you. Together, we can transform the future of work in Nigeria.</p>
      </div>
      <div class="col-lg-6 join-us-img">
        <img src="../../../../assets/images/join-us-img.webp" />
      </div>
    </div>
  </div>
</div>